import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

var firebaseConfig = {
  apiKey: "AIzaSyARZe7l5sldmu8toCpqn9qWOIPJX6cPwgk",
  authDomain: "tf-trello.firebaseapp.com",
  databaseURL: "https://tf-trello.firebaseio.com",
  projectId: "tf-trello",
  storageBucket: "tf-trello.appspot.com",
  messagingSenderId: "445094534989",
  appId: "1:445094534989:web:12ae51cf71381481ac6104",
  measurementId: "G-35PVWK4326"
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();

export const db = firebase.firestore();
export const documentId = firebase.firestore.FieldPath.documentId;