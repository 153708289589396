export let pattern = /(?<amount>\d+)\s*(\([Xx](?<bonus>\d)\)\s+)?[Tf]:[Ff]['´’][Ss]/;
export let placeholder = 'https://via.placeholder.com/32/DCDCDC/000?text=';

export function getUrl (url, data, method = 'GET', auth = null) {
  const headers = new Headers({ 'Accept': 'application/json', 'Content-Type': 'application/json'});
  if (auth) {
    headers.append('Authorization', auth);
  }

  const opts = { headers };
  if (data) {
    opts.method = method === 'GET' ? 'POST' : method;
    opts.body = JSON.stringify(data);
  } else if (method !== 'GET') {
    opts.method = method;
  }

  return fetch(url.startsWith('http') ? url : BASE_URL + url, opts);
}

export function loadCss (url) {
  const css = [...document.styleSheets].find(s => s.href === url);
  if (!css) {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = url;
    document.getElementsByTagName('head')[0].appendChild(link);
  }
}

function whiteOrBlack(color) {
  var r, g, b, hsp;
  if (color.match(/^rgb/)) {
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

    r = color >> 16;
    g = color >> 8 & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b)
  );

  // Using the HSP value, determine whether the color is light or dark
  // if (hsp > 127.5) {
  if (hsp > 170) {
    return 'black';
  }
  else {
    return 'white';
  }
}

export function invertColor (color) {
  const d = document.createElement('div');
  d.style.color = color;
  document.body.appendChild(d);
  return whiteOrBlack(window.getComputedStyle(d).color);
}

export function extractAmount (text) {
  const matches = text.match(pattern);
  if (matches) {
    const b = parseFloat(matches.groups.bonus || 1);
    return parseFloat(matches.groups.amount) * b;
  }
  return 0;
}

export function extractBonus (text) {
  const matches = text.match(pattern);
  if (matches) {
    return parseFloat(matches.groups.bonus || 1);
  }
  return 1;
}