import { wrap } from 'svelte-spa-router/wrap'

import Login from './components/Login.svelte';
import Main from './components/Main.svelte';
import Config from './components/Config.svelte';
import Profile from './components/Profile.svelte';
import NotFound from './components/NotFound.svelte';

import { user as userStore } from './store';

const isAuthenticated = () => {
  let user;
  userStore.subscribe(v => { user = v; });

  return user !== undefined && user !== null;
}

export default {
  '/': wrap({ component: Main, conditions: [ isAuthenticated ] }),
  '/login': Login,
  '/conf': wrap({ component: Config, conditions: [ isAuthenticated ] }),
  '/profile': wrap({ component: Profile, conditions: [ isAuthenticated ] }),
  '*': NotFound
}