<script>
  import { onMount } from 'svelte';

  import ProjectTitle from './ProjectTitle.svelte';
  import Menu from './Menu.svelte';
  import Chart from '../Chart.svelte';
  import Ranking from '../Ranking.svelte';
  import Tasks from '../Tasks.svelte';
  import Loading from './Loading.svelte';

  import { snippet, board, list, tasks } from '../store';

  $: $snippet, load();

  const load = async () => {
    if ($snippet) {
      if (!$board) {
        await board.load($snippet.board);
      }

      if (!$list) {
        await list.load($snippet.list);
      }

      if (!$tasks) {
        await tasks.load();
      }
    }
  };
</script>

{#if !$board || !$list || !$tasks}
  <Loading></Loading>
{:else}
  <main class="section">
    <div class="is-pulled-right"><Menu></Menu></div>
    {#if $board && $list && $tasks}
      <ProjectTitle></ProjectTitle>
      <div class="columns">
        <div class="column">
          <div class="mb-4"><Chart></Chart></div>
          <div class="columns">
            <div class="column"></div>
            <div class="column is-narrow"><Ranking></Ranking></div>
            <div class="column"></div>
          </div>
        </div>
        <div class="column"><Tasks></Tasks></div>
      </div>
    {/if}
  </main>
{/if}