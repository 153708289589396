<script>
  import { onMount } from 'svelte';
  import { push } from 'svelte-spa-router';

  import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';

  import Field from './BulmaField.svelte';

  import { msg, user } from '../store';

  let name;
  let email;

  let isLoading = false;

  async function submit () {
    isLoading = true;
    if ($user.name !== name) {
      await user.changeName(name);
      msg.setMsg('Your name has been saved');
    }
    isLoading = false;
  }

  onMount(() => {
    name = $user.displayName;
    email = $user.email;
  });
</script>
<main class="section">
  <div class="columns">
    <div class="column is-6 is-offset-3">
      <h1 class="title">
        Your <img src="https://i.ibb.co/tDNKvyt/logo.gif" width="50" alt="Timefounder's logo" style="vertical-align: middle;">'s profile
      </h1>
      <form class="box" on:submit|preventDefault={ submit }>
        <div class="columns">
          <div class="column">
            <Field name="Name" bind:value={ name }></Field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <Field name="Email" bind:value={ email } iconLeft={ faEnvelope } isStatic></Field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <button class="button is-primary is-fullwidth" class:is-loading={ isLoading }>Save</button>
          </div>
          <div class="column">
            <button class="button is-text is-fullwidth" type="button" on:click={ () => { push('/') }}>Go back</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</main>