<script>
  import { onMount } from 'svelte';

  import Fa from 'svelte-fa'
  import { faComment } from '@fortawesome/free-solid-svg-icons/faComment';
  import { faPaperclip } from '@fortawesome/free-solid-svg-icons/faPaperclip';
  import marked from 'marked';

  import { conf, tasks, snippet } from './store';

  import { placeholder, invertColor, extractAmount, extractBonus } from './utils';

  async function loadData () {
    if ($snippet) {
			if (!$conf) {
				await conf.load();
			}

			if (!$tasks) {
				await tasks.load();
			}
		}
  }

  onMount(async () => {
    await snippet.load();
    await loadData();
  });
</script>

{#each ($tasks || { raw: [] }).raw as task}
  <div class="media">
    <div class="media-content">
      <div class="content">
        <h2 class="title mb-1">
          <a href={ task.url } target="_blank">{ task.name }</a>
          {#each task.labels as label}
            <span class="tag mr-1" style="color: { invertColor(label.color) }; background-color: { label.color };">
              { label.name }
            </span>
          {/each}
          <span class="tag">
            <span class="icon"><Fa icon={ faComment }></Fa></span>
            <span>{ task.badges.comments }</span>
          </span>
          <span class="tag">
            <span class="icon"><Fa icon={ faPaperclip }></Fa></span>
            <span>{ task.badges.attachments }</span>
          </span>
        </h2>
        <p>{@html marked(task.desc) }</p>
        <div>
          {#each task.members as member}
            <span class="image is-32x32 is-inline-block mr-2 has-text-centered">
              <img src="{ member.avatarUrl ? member.avatarUrl + '/30.png' : placeholder + member.initials }" class="is-rounded" alt="{ member.fullName }" title="{ member.fullName }">
            </span>
          {/each}
        </div>
      </div>
    </div>
    {#if extractAmount(task.desc) > 0}
      <div class="media-right has-text-centered">
        <h2 class="subtitle has-text-weight-semibold">{ new Intl.NumberFormat().format(extractAmount(task.desc)) }</h2>
        {#if extractBonus(task.desc) !== 1}
          <h3 class="subtitle">x{ extractBonus(task.desc) }</h3>
        {/if}
      </div>
    {/if}
  </div>
{/each}