<script>
  import Router from 'svelte-spa-router';
  import { push, location } from 'svelte-spa-router'
  import routes from './routes';

  import { msg, user, snippet, conf } from './store';

  import Loading from './components/Loading.svelte';

  $: $user, load();
  $: $location, analytics();

  const load = async () => {
    if (!$conf) {
      await conf.load();
    }

    if ($user) {
      if (!$snippet) {
        await snippet.load();
        if (!$snippet) {
          push('/conf');
        }
      }
    }
  };

  const analytics = () => {
    window.dataLayer = window.dataLayer || [];
		function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());

		gtag('config', 'G-YGWXRXMFXC', { page_path: $location });
  }
</script>

{#if $msg}
	<div class="notification-container" style="position: absolute; top: 20px; left: 50%;">
		<div class="notification { $msg.class ? $msg.class : '' }" style="position: relative; left: -50%;">
			{ $msg.msg }
			{#if 'PRODUCTION' && $msg.err}
				<pre>{ $msg.err }</pre>
			{/if}
		</div>
	</div>
{/if}

{#if $user === undefined}
  <Loading></Loading>
{:else}
  <Router { routes } on:conditionsFailed={ () => push('/login') } />
{/if}
