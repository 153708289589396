import { writable, get } from 'svelte/store';

import { auth, db, documentId } from './firebase';

import { HueCircle19 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.tableau';
import pattern from 'patternomaly';

import { placeholder, getUrl, extractAmount } from './utils';


function createMsg () {
  const { subscribe, set } = writable(null);
  let to;

  const setVal = val => {
    set(val);
    to = setTimeout(() => { set(null); }, (val.duration * 1000) || 5000);
  }

  const setMsg = text => {
    setVal({ msg: text, class: 'is-success is-light' });
  }

  const setError = text => {
    setVal({ msg: text, class: 'is-danger is-light' });
  }

  const reset = () => {
    set(null);
    clearInterval(to);
  }

  return { subscribe, set: setVal, setMsg, setError, reset }
}

function createUser () {
  const { subscribe, set } = writable(undefined);

  auth.onAuthStateChanged(auth => { set(auth); });

  const login = async (email, password) => {
    await auth.signInWithEmailAndPassword(email, password);
  }

  const logout = () => { auth.signOut(); }

  const resetPassword = email => { auth.sendPasswordResetEmail(email); }

  const register = (email, password) => {
    auth.createUserWithEmailAndPassword(email, password);
  }

  const changeName = async name => {
    await auth.currentUser.updateProfile({ displayName: name });
  }

  const changeEmail = async email => {
    await auth.currentUser.updateEmail(email);
  }

  return { subscribe, login, logout, resetPassword, register, changeName, changeEmail }
}

function createConf () {
  const { subscribe, set } = writable(null);

  const load = async () => {
    const doc = await db.doc('data/config').get();
    set(doc.exists ? doc.data() : false);
  }

  return { subscribe, load };
}

function createSnippet () {
  const { subscribe, set } = writable(undefined);

  const load = async () => {
    const urlParams = document.currentScript ? new URLSearchParams(document.currentScript.src.split('?')[1] || '') : null;
    if (urlParams && urlParams.has('key')) {
      const snippetDoc = await db.doc(`snippets/${ urlParams.get('key') }`).get();
      if (snippetDoc.exists) {
        const snippet = snippetDoc.data();
        const projectDoc = await db.doc(snippet.path).get();
        set(projectDoc.exists ? { id: projectDoc.id, ...projectDoc.data(), snippet } : null);
      } else {
        set(null);
      }
    } else {
      const actor = get(user);
      const projectDoc = await db.doc(`data/${ actor.uid }/projects/current`).get();
      set(projectDoc.exists ? { id: projectDoc.id, ...projectDoc.data() } : null);
    }
  }

  const exists = async (token, board, list) => {
    const u = get(user);
    const docs = await db.collection('data').doc(u.uid).collection('projects').where('token', '==', token).where('board', '==', board).where('list', '==', list).where(documentId(), '!=', 'current').get();
    return docs.docs.length ? docs.docs[0].id : false;
  }

  const getId = async project => {
    const u = get(user);
    const docs = await db.collection('snippets').where('path', '==', `data/${ u.uid }/projects/${ project }`).get();
    return docs.docs.length ? docs.docs[0].id : null;
  }

  const generateKey = () => Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

  const create = async (data, key) => {
    const u = get(user);
    if (!key) {
      key = generateKey();
    }
    await db.collection('data').doc(u.uid).collection('projects').doc(key).set(data);
    return key;
  }

  const createSnippet = async (project, key) => {
    const u = get(user);
    if (!key) {
      key = generateKey();
    }
    await db.collection('snippets').doc(key).set({ path: `data/${ u.uid }/projects/${ project }` });
    return key;
  }

  return { subscribe, exists, getId, load, create, createSnippet, generateKey }
}

function createProjects () {
  const { subscribe, set } = writable([]);

  const load = async user => {
    const docs = await db.collection('data').doc(user).collection('projects').where(documentId(), '!=', 'current').get();
    const result = [];
    docs.forEach(async d => {
      const data = d.data();
      const detail = await Promise.all([board.load(data.board, false), list.load(data.list, false)]);
      data.boardData = detail[0];
      data.listData = detail[1];
      result.push(data);
    });
    set(result);
  }

  return { subscribe, load }
}

function createBoards () {
  const { subscribe, set } = writable(null);

  const load = async token => {
    const c = get(conf);
    const resp = await getUrl(`${ c.trello.apiUrl }/members/me/boards?key=${ c.appKey }&token=${ token }`);
    set(resp.ok ? await resp.json() : false);
  }

  return { subscribe, load }
}

function createBoard () {
  const { subscribe, set } = writable(null);

  const load = async (board, save = true) => {
    const c = get(conf);
    const snip = get(snippet);
    const resp = await getUrl(`${ c.trello.apiUrl }/boards/${ board }?key=${ c.appKey }&token=${ snip.token }`);
    const result = resp.ok ? await resp.json() : false;
    if (save) {
      set(result);
    } else {
      return result;
    }
  }

  return { subscribe, load }
}

function createLists () {
  const { subscribe, set } = writable(null);

  const load = async (token, board) => {
    const c = get(conf);
    const resp = await getUrl(`${ c.trello.apiUrl }/boards/${ board }/lists?key=${ c.appKey }&token=${ token }`);
    set(resp.ok ? await resp.json() : false);
  }

  return { subscribe, load }
}

function createList () {
  const { subscribe, set } = writable(null);

  const load = async (list, save = true) => {
    const c = get(conf);
    const snip = get(snippet);
    const resp = await getUrl(`${ c.trello.apiUrl }/lists/${ list }?key=${ c.appKey }&token=${ snip.token }`);
    const result = resp.ok ? await resp.json() : false;
    if (save) {
      set(result);
    } else {
      return result;
    }
  }

  return { subscribe, load }
}

function createTasks () {
  const { subscribe, set } = writable(null);

  function generateColors (data) {
    const howManyTimes = Math.ceil(Object.keys(data).length / HueCircle19.length);
    let colors = HueCircle19;
    let withPattern = [];
    for (let idx = 0; idx < howManyTimes - 1; idx++) {
      withPattern = withPattern.concat(HueCircle19);
    }
    return colors.concat(pattern.generate(withPattern));
  }

  function process (data) {
    const result = {};
    let total = 0;
    for (const task of data) {
      const amount = extractAmount(task.desc)
      for (const member of task.members) {
        if (!Object.keys(result).includes(member.username)) {
          const url = member.avatarUrl ? member.avatarUrl + '/30.png' : placeholder + member.initials;
          result[member.username] = { name: member.fullName, amount: 0, avatar: url }
        }
        result[member.username].amount += amount;
        total += amount;
      }
    }

    const colors = generateColors(result);
    const labels = [];
    const amounts = [];
    const values = Object.values(result);
    for (const idx in values) {
      labels.push(values[idx].name);
      amounts.push(values[idx].amount);
      values[idx].color = colors[idx];
      values[idx].percent = (values[idx].amount / total) * 100;
    }

    return {
      raw: data,
      processed: result,
      total,
      chartData: { labels: labels, datasets: [{ data: amounts, backgroundColor: colors }]},
      hidden: []
    }
  }

  const load = async () => {
    const c = get(conf);
    const proj = get(snippet);
    const resp = await getUrl(`${ c.trello.apiUrl }/lists/${ proj.list }/cards?key=${ c.appKey }&token=${ proj.token }&members=true`);
    set(resp.ok ? process(await resp.json()) : false);
  }

  return { subscribe, load };
}

export const msg = createMsg();
export const user = createUser();
export const conf = createConf();
export const snippet = createSnippet();
// export const project = createProject();
export const projects = createProjects();
export const boards = createBoards();
export const board = createBoard();
export const lists = createLists();
export const list = createList();
export const tasks = createTasks();