<script>
  import Fa from 'svelte-fa';
  import { faCode } from '@fortawesome/free-solid-svg-icons/faCode';
  import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';

  import { msg, board, list, tasks, snippet } from '../store';

  let active = false

  async function copy (name) {
    active = false;
    let proj = await snippet.exists($snippet.token, $snippet.board, $snippet.list);
    if (!proj) {
      proj = await snippet.create({ token: $snippet.token, board: $snippet.board, list: $snippet.list })
    }
    let key = await snippet.getId(proj);
    if (!key) {
      key = await snippet.createSnippet(proj);
    }

    const script = document.createElement('script');
    script.defer = true;
    script.src = `${ window.location.origin }/build/tf-${ name.toLowerCase() }.0.10.js?key=${ key }`;
    await navigator.clipboard.writeText(script.outerHTML);
    msg.setMsg(`${ name } snippet copied!`);
  }
</script>

<h1 class="title">
  <div class="dropdown" class:is-active={ active }>
    <div class="dropdown-trigger">
      <button class="button is-text px-1" aria-haspopup="true" aria-controls="dropdown-menu" on:click={ () => active = !active }>
        <Fa icon={ faCode }></Fa>
        <Fa icon={ faCaretDown }></Fa>
      </button>
    </div>
    <div class="dropdown-menu" id="dropdown-menu" role="menu">
      <div class="dropdown-content">
        <!-- svelte-ignore a11y-missing-attribute -->
        <a class="dropdown-item" on:click={ () => { copy('Chart') } }>Chart</a>
      </div>
      <div class="dropdown-content">
        <!-- svelte-ignore a11y-missing-attribute -->
        <a class="dropdown-item" on:click={ () => { copy('Ranking') } }>Ranking</a>
      </div>
      <div class="dropdown-content">
        <!-- svelte-ignore a11y-missing-attribute -->
        <a class="dropdown-item" on:click={ () => { copy('Tasks') } }>Tasks</a>
      </div>
    </div>
  </div>
  <a href={ $board.url } target="_blank">{ $board.name } - { $list.name }</a>
  <span class="tag is-success is-light is-large">
    { new Intl.NumberFormat().format(($tasks || { total: 0 }).total) }
  </span>
</h1>