<script>
  import { link, push } from 'svelte-spa-router'

  import Fa from 'svelte-fa';
  import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
  import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
  import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
  import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';

  import { user } from '../store';

  let active = false;

  function logout () {
    user.logout()
    push('/login');
  }
</script>

<div class="dropdown is-right" class:is-active={ active }>
  <div class="dropdown-trigger">
    <button class="button is-text px-1" aria-haspopup="true" aria-controls="dropdown-menu" on:click={ () => active = !active }>
      <img src="https://i.ibb.co/tDNKvyt/logo.gif" width="50" alt="Timefounder's logo">
      <Fa icon={ faCaretDown }></Fa>
    </button>
  </div>
  <div class="dropdown-menu" id="dropdown-menu" role="menu">
    <div class="dropdown-content">
      <a href="/profile" use:link class="dropdown-item">
        <span class="icon"><Fa icon={ faUser }></Fa></span>
        <span>Profile</span>
      </a>
      <a href="/conf" use:link class="dropdown-item">
        <span class="icon"><Fa icon={ faCog }></Fa></span>
        <span>Configuration</span>
      </a>
      <hr class="dropdown-divider">
      <!-- svelte-ignore a11y-missing-attribute -->
      <a class="dropdown-item" on:click={ logout }>
        <span class="icon"><Fa icon={ faSignOutAlt }></Fa></span>
        <span>Log out</span>
      </a>
    </div>
  </div>
</div>