<script>
  export let classes = null;
</script>

<section class={ classes ? 'hero ' + classes : 'hero is-fullheight' }>
  <slot name="header"></slot>
  <div class="hero-body">
    <div class="container has-text-centered">
      <slot><h1 class="title">Loading...</h1></slot>
    </div>
  </div>
  <slot name="footer"></slot>
</section>