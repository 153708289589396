<script>
	import Chart from 'chart.js';

	import { onMount } from 'svelte';

	import { conf, tasks, snippet } from './store';

	export let options = { aspectRatio: 1.2, responsive: true, legend: { display: false } };

	let chart;
	let canvasRef;

	function toggle (member) {
		const idx = $tasks.chartData.labels.findIndex(l => l === member);
		chart.getDatasetMeta(0).data[idx].hidden = !chart.getDatasetMeta(0).data[idx].hidden;
		chart.update();
	}

	document.addEventListener('toggle-member', e => {
		if (e.type === 'toggle-member' && e.detail.list === $snippet.list) {
			toggle(e.detail.member);
		}
	});

	const loadSnippet = async () => {
		await snippet.load();
	}

	const loadData = async () => {
		if (!$conf) {
			await conf.load();
		}

		if (!$tasks) {
			await tasks.load();
		}

		chart = new Chart(canvasRef, { type: 'pie', data: $tasks.chartData, options: options });
	}

	onMount(async () => {
		await loadSnippet();
		await loadData();
	});
</script>

<canvas bind:this={ canvasRef }></canvas>