<script>
  import { createEventDispatcher } from 'svelte';

  import Fa from 'svelte-fa';

  export let name;
  export let value = null;
  export let type = 'text';
  export let placeholder = null;
  export let help = null;
  export let helpClasses = null;
  export let iconLeft = null;
  export let iconLeftClickable = false;
  export let iconRight = null;
  export let iconRightClickable = false;
  export let isStatic = false;

  const slotsNames = arguments && arguments[1].$$slots ? Object.keys(arguments[1].$$slots) : [];
  const hasAddons = slotsNames.includes('rightAddon') || slotsNames.includes('leftAddon');

  const dispatch = createEventDispatcher();
</script>

<div class="field">
  <label for={ name } class="label">{ name }</label>
  <div class="field" class:has-addons={ hasAddons }>
    <slot name="leftAddon"></slot>
    <div class="control" class:has-icons-left={ iconLeft } class:has-icons-right={ iconRight } class:is-expanded={ hasAddons }>
      <slot>
        <input id={ name } class="input" class:is-static={ isStatic } type={ type } value={ value } on:input={ (e) => value = e.target.value } placeholder={ placeholder }>
      </slot>
      {#if iconLeft}
        <span class="icon is-small is-left { iconLeftClickable ? 'has-text-primary"' : '' }" style="{ iconLeftClickable ? 'cursor: pointer; pointer-events: all;' : '' }" on:click={ () => { dispatch('icon-left-clicked') } }>
          <Fa icon={ iconLeft }></Fa>
        </span>
      {/if}
      {#if iconRight}
        <span class="icon is-small is-right { iconRightClickable ? 'has-text-primary' : '' }" style="{ iconRightClickable ? 'cursor: pointer; pointer-events: all;' : '' }" on:click={ () => { dispatch('icon-right-clicked') } }>
          <Fa icon={ iconRight }></Fa>
        </span>
      {/if}
    </div>
    <slot name="rightAddon"></slot>
  </div>

  {#if help}
    <p class="help { helpClasses }">{ help }</p>
  {/if}
</div>