<script>
  import { onMount, createEventDispatcher } from 'svelte';

  import { conf, tasks, snippet } from './store';

  export let active = true;
  export let hidden = [];

  const canvasRefs = {};
  $: canvasRefs, fillColors();

  function byAmount (a, b) {
    if (a.amount > b.amount) {
      return -1;
    }
    if (a.amount < b.amount) {
      return 1;
    }
    return 0;
  }

  function fill (member, color) {
    if (canvasRefs[member]) {
      const ctx = canvasRefs[member].getContext('2d');
      ctx.fillStyle = color;
      ctx.fillRect(0, 0, 30, 30);
    }
  }

  function fillColors () {
    for (const member of Object.values(($tasks || { processed: []}).processed)) {
      fill (member.name, member.color);
    }
  }

  function toggle (member) {
    if (active) {
      dispatch('toggle', member.name);
      var event = new CustomEvent('toggle-member', { detail: { list: $snippet.list, member: member.name } });
      document.dispatchEvent(event);
      if (hidden.includes(member.name)) {
        hidden.splice(hidden.indexOf(member.name), 1);
      } else {
        hidden.push(member.name);
      }
      hidden = hidden;
    }
  }

  async function loadData () {
    if ($snippet) {
			if (!$conf) {
				await conf.load();
			}

			if (!$tasks) {
				await tasks.load();
			}
		}
  }

  onMount(async () => {
    await snippet.load();
    await loadData();
  });

  const dispatch = createEventDispatcher();
</script>

<table class="table is-hoverable is-fullwidth is-narrow">
  <tbody>
    {#each Object.values(($tasks || { processed: []}).processed).sort(byAmount) as member}
      <tr class="is-size-7-mobile is-size-5-tablet" class:is-clickable={ active } style="{ hidden.includes(member.name) ? 'text-decoration: line-through;' : '' }" on:click={ toggle(member) }>
        {#if active}
          <td>
            {#if !hidden.includes(member.name)}
              <canvas width="30" height="30" bind:this={ canvasRefs[member.name] }></canvas>
            {/if}
          </td>
        {/if}
        <td>
          <figure class="image is-32x32">
            <img src={ member.avatar } class="is-rounded" alt={ member.name }>
          </figure>
        </td>
        <td>{ member.name }</td>
        <td class="has-text-right">{ new Intl.NumberFormat().format(member.amount) }</td>
        <td class="has-text-right">{ member.percent.toFixed(2) }%</td>
      </tr>
    {/each}
  </tbody>
</table>