<script>
  import { onMount } from 'svelte';

  import { push } from 'svelte-spa-router';

  import Fa from 'svelte-fa';
  import { faTrello } from '@fortawesome/free-brands-svg-icons/faTrello';
  import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';

  import Field from './BulmaField.svelte';

  import { conf, user, snippet, projects, boards, board as boardStore, lists, list as listStore, tasks } from '../store';

  let loading = false;
  let saving = false;

  let token;
  let board;
  let list;

  $: token, loadBoards();
  $: board, loadLists();

  function areEqual(proj) {
    return $snippet && $snippet.token === proj.token && $snippet.board === proj.board && $snippet.list === proj.list;
  }

  async function loadBoards () {
    if (token) {
      loading = true;
      await boards.load(token);
      loading = false;
    }
  }

  async function loadLists () {
    if (board) {
      loading = true;
      await lists.load(token, board);
      loading = false;
    }
  }

  async function changeProject (proj) {
    ({ token, board, list } = proj);
    save();
  }

  async function save () {
    saving = true;
    await snippet.create({ token, board, list }, 'current');
    await snippet.load()
    await Promise.all([boardStore.load($snippet.board), listStore.load($snippet.list), tasks.load()]);
    saving = false;
    push('/');
  }

  onMount(async () => {
    if (!$snippet) {
      await snippet.load();
    }

    if ($snippet) {
      if (!$projects.length) {
        await projects.load($user.uid);
      }

      token = $snippet.token;
      board = $snippet.board;
      list = $snippet.list;
    }
  })
</script>

<main class="section">
  <div class="columns">
    <div class="column is-6 is-offset-3">
      <h1 class="title">
        <img src="https://i.ibb.co/tDNKvyt/logo.gif" width="50" alt="Timefounder's logo" style="vertical-align: middle;">'s Configuration
      </h1>

      {#if $projects.length}
        <section class="menu">
          <p class="menu-label">Projects you have copied snippets</p>
          <ul class="menu-list">
            {#each $projects as proj}
              <li>
                {#if areEqual(proj)}
                  <!-- svelte-ignore a11y-missing-attribute -->
                  <a style="pointer-events: none;">
                    <span class="icon"><Fa icon={ faCheck }></Fa></span>
                    <span>{ proj.boardData.name } - { proj.listData.name }</span>
                  </a>
                {:else}
                  <!-- svelte-ignore a11y-missing-attribute -->
                  <a on:click={ () => changeProject(proj) }>{ proj.boardData.name } - { proj.listData.name }</a>
                {/if}
              </li>
            {/each}
          </ul>
        </section>
      {/if}

      <div class="columns mt-2">
        <div class="column">
          <div class="field">
            <!-- svelte-ignore a11y-label-has-associated-control -->
            <label class="label">1. Go to your Trello and add some rewarded tasks</label>
            <a href="https://timefounder.gitbook.io/timefounder/#track-contributions" target="_blank">Read how</a>
          </div>
        </div>
      </div>

      <form on:submit|preventDefault={ save }>
        <div class="columns">
          <div class="column">
            <Field name="2. Trello's security token" bind:value={ token } placeholder="Paste here your Trello's security token" help="Click the button to generate a new token &uarr;" helpClasses="has-text-right pr-4" on:change={ () => { board = null; list = null } }>
              <div class="control" slot="rightAddon">
                {#if $conf}
                  <a href="{ $conf.trello.authUrl + $conf.appKey }" target="_blank" class="button" class:is-loading={ loading }>
                    <Fa icon={ faTrello }></Fa>
                  </a>
                {/if}
              </div>
            </Field>

            {#if $boards}
              <Field name="3. Board">
                <div class="select">
                  <!-- svelte-ignore a11y-no-onchange -->
                  <select id="Board" bind:value={ board } on:change={ () => { list = null; } }>
                    <option value={ null } disabled>Select the board</option>
                    {#each $boards as b}
                      <option value={ b.id }>{ b.name }</option>
                    {/each}
                  </select>
                </div>
              </Field>

              {#if $lists}
                <Field name="4. List">
                  <div class="select">
                    <select bind:value={ list }>
                      <option value={ null } disabled>Select the list</option>
                      {#each $lists as l}
                        <option value={ l.id }>{ l.name }</option>
                      {/each}
                    </select>
                  </div>
                </Field>
              {/if}
            {/if}
          </div>
        </div>
        <div class="columns is-mobile">
          <div class="column"><button class="button is-primary is-fullwidth" class:is-loading={ saving }>View data</button></div>
          <div class="column">
            <button class="button is-fullwidth is-text" type="button" on:click={ () => push('/') }>Go back</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</main>