<script>
  import { push } from 'svelte-spa-router'

  import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
  import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
  import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
  import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash';

  import { msg, user } from '../store';

  import Field from './BulmaField.svelte';

  const texts = {
    login: { preTitle: 'Log in to ', btn: 'Log me in'},
    forgot: { preTitle: 'Request the ', postTitle: '\'s reset password email', btn: 'Send me the reset password email'},
    register: { preTitle: 'Register your ', postTitle: '\'s account', btn: 'Sign me in' }
  }
  let mode = 'login';

  let email = '';
  let password = '';
  let showPassword = false;

  let loading = false;

  async function submit () {
    loading = true;
    try {
      if (mode === 'login') {
        await user.login(email, password);
        push('/');
      } else if (mode === 'forgot') {
        await user.resetPassword(email);
        msg.setMsg('The password reset email will be in your inbox');
        mode = 'login';
      } else if (mode === 'register') {
        await user.register(email, password);
        msg.setMsg('Your account has been created');
        mode = 'login';
      }
    } catch (err) {
      msg.setError(err);
    }
    loading = false;
  }
</script>

<main class="section">
  <div class="columns">
    <div class="column is-6 is-offset-3">
      <div class="box">
        <h1 class="title">
          { texts[mode].preTitle }
          <img src="https://i.ibb.co/tDNKvyt/logo.gif" width="50" alt="Timefounder's logo" style="vertical-align: middle;">
          { texts[mode].postTitle || '' }
        </h1>
        <form on:submit|preventDefault={ submit }>
          <Field name="Email" type="email" bind:value={ email } iconLeft={ faEnvelope } placeholder="johndoe@example.org"></Field>
          {#if ['login', 'register'].includes(mode)}
            <Field name="Password" type="{ showPassword ? 'text' : 'password' }" bind:value={ password } iconLeft={ faLock } iconRight={ showPassword ? faEyeSlash : faEye } iconRightClickable={ true } on:icon-right-clicked={ () => { showPassword = !showPassword } }></Field>
          {/if}
          <div class="columns">
            {#if mode === 'login' }
              <div class="column">
                <!-- svelte-ignore a11y-missing-attribute -->
                <a on:click={ () => mode = 'forgot' }>Forgot password</a>
              </div>
            {/if}
            {#if ['login', 'forgot'].includes(mode)}
              <div class="column" class:is-narrow={ mode === 'login' }>
                <!-- svelte-ignore a11y-missing-attribute -->
                <a on:click={ () => mode = 'register' }>I have no account</a>
              </div>
            {/if}
            {#if ['forgot', 'register'].includes(mode)}
              <div class="column is-narrow">
                <!-- svelte-ignore a11y-missing-attribute -->
                <a on:click={ () => mode = 'login' }>Log in</a>
              </div>
            {/if}
          </div>
          <div class="columns">
            <div class="column">
              <button class="button is-primary is-fullwidth" class:is-loading={ loading }>{ texts[mode].btn }</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</main>